import MsgTrans from '@components/common/i18n/message-trans'
import { AcademicCap } from '@components/common/icons/custom-icons'
import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { BookOpen, Check, Clock, Download, FileText } from 'react-feather'

const COMMON_IMAGE_PATH = '/images/home/courses/card/'

const HOVER_BG = [
  'hover:bg-orange-100 hover:border-orange',
  'hover:bg-orange2-50 hover:border-orange2-400 hover:bg-opacity-75',
  'hover:bg-grey-200 hover:border-grey',
  'hover:bg-blue-50 hover:border-blue-300 hover:bg-opacity-50',
  'hover:bg-red-50 hover:border-red-200',
  'hover:bg-yellow-100 hover:bg-opacity-50 hover:border-yellow-200',
  'hover:bg-violet-50 hover:border-violet-100',
  'hover:bg-pink hover:border-pink',
]

type CourseCardProps = {
  course: any // Adjust type based on actual structure
  onPDFDownload: (courseCode: string, gradeOptions: number[]) => void
  isCoursePage?: boolean
}

const CourseCard = ({
  course,
  onPDFDownload,
  isCoursePage,
}: CourseCardProps) => {
  const getRandomHoverBg = () => {
    const randomIndex = Math.floor(Math.random() * HOVER_BG.length)
    return HOVER_BG[randomIndex]
  }

  return (
    <a
      href={`/register/?course=${course.trialCourseQuery}&utm_medium=homepage-course-card`}
      className={`p-2 w-full ${
        isCoursePage
          ? 'visible lg:hidden md:w-80 max-w-full'
          : 'md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-md'
      }`}
    >
      <div
        className={`pb-0 shadow hover:shadow-lg transition-all duration-200 rounded-md bg-white ${getRandomHoverBg()} border border-grey-100 h-full flex flex-col justify-between`}
      >
        <div className="relative w-full">
          {course.isBestSeller && (
            <div className="absolute top-0 left-0 z-10 bg-orange rounded-md shadow-md px-3 py-1 text-white font-600">
              Bestseller
            </div>
          )}
          <FastlyImage
            src={`${COMMON_IMAGE_PATH}${course.image}.png`}
            webpSrc={`${COMMON_IMAGE_PATH}${course.image}.webp`}
            alt={course.altText}
            className="max-w-full"
            width={530}
            height={244}
          />
          <div className="absolute top-0 right-0 z-10 bg-orange-100 bg-opacity-50 rounded-sm shadow-md px-2 font-500 text-base">
            <Trans>Age</Trans> {course.Age}
          </div>
        </div>

        <div className="space-y-2 pt-3 pb-2 p-3">
          <a
            href={course.coursePageUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3
              className="leading-tight font-600 duration-300 cursor-pointer hover:text-orange"
              style={{ fontSize: '1.4rem' }}
            >
              {course.title}
            </h3>
          </a>
          <div className="flex text-sm flex-wrap">
            <div className="flex items-center" style={{ width: '102px' }}>
              <AcademicCap className="text-orange w-5" />
              <span className="mt-1" style={{ marginLeft: '2px' }}>
                <Trans>Grade</Trans> {course.grade}
              </span>
            </div>
            <div className="flex items-center">
              <FileText className="text-orange w-4 mr-1" />
              <span>
                {course.activites}+ <Trans>Activities</Trans>
              </span>
            </div>
            <div className="flex items-center" style={{ width: '102px' }}>
              <BookOpen
                className="text-orange w-4 mr-1"
                style={{ marginLeft: '2px' }}
              />
              <span>
                {course.lessons} <Trans>Lessons</Trans>
              </span>
            </div>
            <div className="flex items-center">
              <Clock className="text-orange w-4 mr-1" />
              <span>
                {course.duration} <Trans>months</Trans>
              </span>
            </div>
          </div>
          {!isCoursePage && (
            <p className="text-sm" style={{ minHeight: '70px' }}>
              <MsgTrans msg={course.description} />
            </p>
          )}

          <h4 className="font-600">
            <Trans>Learning outcomes</Trans>
          </h4>

          <div className="space-y-1 text-sm">
            {course.outcomes.map((outcome, index) => (
              <div key={index} className="flex items-start">
                <span
                  className="rounded-full bg-orange-200"
                  style={{ padding: '2px', marginTop: '2px' }}
                >
                  <Check
                    height={9}
                    width={9}
                    strokeWidth={3}
                    className="text-orange"
                  />
                </span>
                <p className="ml-1 text-left">
                  <MsgTrans msg={outcome} />
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="relative w-full">
          {['cp', 'cc', 'cgm'].includes(course.courseCode) ? (
            <button
              className="text-sm flex items-center align-middle font-600 py-2 h-10 text-orange mx-auto focus:outline-none z-20 hover:underline relative"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onPDFDownload(course.courseCode, course.gradeOptions)
              }}
            >
              <span className="px-1">
                <Trans>Download curriculum</Trans>
              </span>
              <Download size={15} />
            </button>
          ) : (
            <a
              href={course.curriculumPDFLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm flex items-center justify-center align-middle font-600 h-10 text-orange mx-auto focus:outline-none z-10 hover:underline"
            >
              <span className="px-1">
                <Trans>Download curriculum</Trans>
              </span>
              <Download size={15} />
            </a>
          )}
          <div className="px-3 mb-4">
            <Link
              href={`/register/?course=${course.trialCourseQuery}&utm_medium=homepage-course-card`}
              prefetch={false}
            >
              <a className="btn border-orange text-orange hover:bg-orange hover:text-white duration-300 w-full text-center text-lg">
                <Trans>Try a free lesson</Trans>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </a>
  )
}

export default CourseCard
